function generalFunction(){
    const fullpageClass = document.querySelector('#fullpage');
    
    if(fullpageClass!=null){
        console.log(fullpageClass);
        new fullpage('#fullpage', {
            licenseKey: '4759360C-FC314EB0-A85BC8C3-E0C6677F',
            autoScrolling:true,
		    scrollHorizontally: true,
            controlArrows: true,
            keyboardScrolling: true,
        });
    }
}


window.addEventListener("load", function(event){  
    generalFunction(); 
});

