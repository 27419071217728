import { __ } from '@wordpress/i18n';
import { InnerBlocks } from '@wordpress/block-editor';
import "./index.css";

export default function save(props){
    const { attributes} = props;
    const { blockId, slidesToShow, slidesToScroll, autoplay, autoplaySpeed, arrows, dots, infinite} = attributes;
   
    return(
        <div id='fullpage'>
            <InnerBlocks.Content />
        </div>

    );

    
}

