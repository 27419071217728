import { __ } from '@wordpress/i18n';
import { useBlockProps, InnerBlocks, InspectorControls } from '@wordpress/block-editor';
import{Panel, PanelBody, TextControl } from "@wordpress/components"
import { useRef, useEffect} from "@wordpress/element";

export default function Edit(props){

    const { attributes, setAttributes, clientId} = props;
    const { blockId, slidesToShow, slidesToScroll, autoplay, autoplaySpeed, arrows, dots, infinite} = attributes;

    if ( ! blockId ) {
        setAttributes( { blockId: clientId } );
    }
    
    const changeGrid = () =>{
        const contentSlides = document.querySelector(`.class-id-${clientId}>.block-editor-inner-blocks>.block-editor-block-list__layout`);
        contentSlides.style.gridTemplateColumns = `repeat(${slidesToShow}, 1fr)`;
        console.log(contentSlides);
    }
  

    useEffect(() => {
        changeGrid();
    }, []);

    return(
        <>    
            <InspectorControls>
                <Panel>
                    <PanelBody title={__('Opciones de slider')} className="slider-options">
                        <TextControl 
                            className='slider-options__values'
                            label={__("sliders por pantalla")}
                            value= {slidesToShow}
                            type="number"
                            onChange={(newslidesToShow)=> setAttributes({
                                slidesToShow:parseInt(newslidesToShow),
                                ...changeGrid(),
                            })}
                            min={1}
                        />

                        <div className="options-toggle">
                            <label className="options-toggle__labels" for={"dots"+clientId}>
                                <span className="options-toggle__text">{__("Agregar puntos")}</span>
                                <input
                                    id={"dots-"+clientId}
                                    type="checkbox"
                                    className="options-toggle__checkbox"
                                    name="slider_navegation"
                                    value={dots}
                                    checked={dots}
                                    onChange={()=>{setAttributes({dots:!dots})}}
                                /> 
                                <span className="options-toggle__slide">
                                    <span className='components-form-toggle__thumb'></span>
                                </span> 
                                
                            </label>
                        </div>

                        <div className="options-toggle">
                            <label className="options-toggle__labels" for={"arrows-"+clientId}>
                                <span className="options-toggle__text">{__("Agregar flechas")}</span>
                                <input
                                    id={"arrows-"+clientId}
                                    type="checkbox"
                                    className="options-toggle__checkbox"
                                    name="slider_navegation"
                                    value={arrows}
                                    checked={arrows}
                                    onChange={()=>{setAttributes({arrows:!arrows})}}
                                />
                                <span className="options-toggle__slide">
                                    <span className='components-form-toggle__thumb'></span>
                                </span> 
                            </label>
                        </div>

                        <div className="options-toggle">
                            <label className="options-toggle__labels" for={"infinite-"+clientId}>
                                <span className="options-toggle__text">{__("Slider infinito")}</span>
                                <input
                                    id={"infinite-"+clientId}
                                    type="checkbox"
                                    className="options-toggle__checkbox"
                                    name="slider_navegation"
                                    value={infinite}
                                    checked={infinite}
                                    onChange={()=>{setAttributes({infinite:!infinite})}}
                                />
                                <span className="options-toggle__slide">
                                    <span className='components-form-toggle__thumb'></span>
                                </span> 
                            </label>
                        </div>

                        <div className="options-toggle">
                            <label className="options-toggle__labels" for={"autoplay-"+clientId}>
                                <span className="options-toggle__text">{__("Autoplay")}</span>
                                <input
                                    id={"autoplay-"+clientId}
                                    type="checkbox"
                                    className="options-toggle__checkbox"
                                    name="slider_navegation"
                                    value={autoplay}
                                    checked={autoplay}
                                    onChange={()=>{setAttributes({autoplay:!autoplay})}}
                                />
                                <span className="options-toggle__slide">
                                    <span className='components-form-toggle__thumb'></span>
                                </span> 
                            </label>
                        </div>

                        <TextControl 
                            className='slider-options__speed'
                            label={__("Velocidad transición")}
                            value= {autoplaySpeed}
                            type="number"
                            onChange={(newautoplaySpeed)=>{setAttributes({autoplaySpeed:parseInt(newautoplaySpeed)}); }}
                            min={1}
                        />

                    </PanelBody>
                </Panel>
            </InspectorControls>
            <div id="fullpage-content"
                { ...useBlockProps({
                    className:`class-id-${clientId}`
                })}
            >
                <InnerBlocks allowedBlocks={ ['plm/screen']} 
                />
            </div>
            
        </>
    )
}

