import {registerBlockType} from '@wordpress/blocks';
import {__} from '@wordpress/i18n';
import Edit from "./edit";
import Save from "./save";

registerBlockType("plm/screen",{
    title: __("Screen"),
    description: __('Single section page'),
    icon: "welcome-add-page",
    parent:['plm/fullpage'],
    edit: Edit,
    save: Save
})